import settings from "../../settings";

//hotel images
const Hyatt_Palm_Springs = `${settings.IMAGES_BASE_URL}/images/hotelpartners/Hyatt_Palm_Springs.jpg`;
const Hotel_Paseo = `${settings.IMAGES_BASE_URL}/images/hotelpartners/Hotel_Paseo.jpg`;
const JW_Marriott = `${settings.IMAGES_BASE_URL}/images/hotelpartners/JW_Marriott.jpg`;
const Renaissance_Palm_Springs = `${settings.IMAGES_BASE_URL}/images/hotelpartners/Renaissance_Palm_Springs.jpg`;
const Residence_Inn_La_Quinta = `${settings.IMAGES_BASE_URL}/images/hotelpartners/Residence_Inn_La_Quinta.jpg`;

const La_Quinta_Resort_Club = `${settings.IMAGES_BASE_URL}/images/hotelpartners/La_Quinta_Resort_Club.jpg`;
const Rancho_Mirage = `${settings.IMAGES_BASE_URL}/images/hotelpartners/Rancho_Mirage.jpg`;
const miramonte_indian_wells = `${settings.IMAGES_BASE_URL}/images/hotelpartners/miramonte_indian_wells.jpg`;
const Renaissance_Esmeralda_Indian_Wells = `${settings.IMAGES_BASE_URL}/images/hotelpartners/Renaissance_Esmeralda_Indian_Wells.jpg`;
const conrad = `${settings.IMAGES_BASE_URL}/images/hotelpartners/conrad.jpg`;
const ritzRancho = `${settings.IMAGES_BASE_URL}/images/hotelpartners/ritz-carlton-rancho.jpg`;
const driftHotel = `${settings.IMAGES_BASE_URL}/images/hotelpartners/drift-hotel.jpg`;

// South carolina hotels
const Greenville_Marriot = `${settings.IMAGES_BASE_URL}/images/hotelpartners/Greenville_Marriot.jpg`;
const Westin_Poinsett = `${settings.IMAGES_BASE_URL}/images/hotelpartners/Westin_Poinsett.jpg`;
const Aloft_Greenville_Downtown = `${settings.IMAGES_BASE_URL}/images/hotelpartners/Aloft_Greenville_Downtown.jpg`;
const Crowne_Plaza_Greenville = `${settings.IMAGES_BASE_URL}/images/hotelpartners/Crowne_Plaza_Greenville.jpg`;
const Embassy_Suites_Greenville = `${settings.IMAGES_BASE_URL}/images/hotelpartners/Embassy_Suites_Greenville.jpg`;
const ACHotelGreenville = `${settings.IMAGES_BASE_URL}/images/hotelpartners/AC_Hotel_Greenville.jpg`;
const Hampton_Inn_Greenville_Riverplace = `${settings.IMAGES_BASE_URL}/images/hotelpartners/Hampton_Inn_Greenville_Riverplace.jpg`;
const Spartanburg_Marriot = `${settings.IMAGES_BASE_URL}/images/hotelpartners/Spartanburg_Marriot.jpg`;
const Hyatt_Indian_Wells = `${settings.IMAGES_BASE_URL}/images/hotelpartners/Hyatt_Indian_Wells.jpg`;
const AcmeHouse = `${settings.IMAGES_BASE_URL}/images/hotelpartners/acme-house.jpg`;
const bohemaian = `${settings.IMAGES_BASE_URL}/images/hotelpartners/The-Bohemian.jpg`;
const hartness = `${settings.IMAGES_BASE_URL}/images/hotelpartners/hotel-hartness-exterior.jpg`;

//hotel logos
const crownplazahotel = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/desktop/crown-plaza.jpg`;
const achotel = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/ac-hotel.jpg`;
const hotelpaseo = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/hotelpaseo.jpg`;
const hamptonhotel = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/hampton-hotel.jpg`;
const embassyhotel = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/embassy-hotel.jpg`;
const greenvillehotel = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/marriott_sc_logo.jpg`;
const hyattpalmspringshotel = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/hyatt-palm-springs-hotel.jpg`;
const renaissancehotel = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/renaissance-hotel.jpg`;
const jwmarriotthotel = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/jw-marriott-hotel.jpg`;
const laQuintahotel = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/laQuinta-hotel.jpg`;
const ritzhotel = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/ritz-hotel.jpg`;
const Westinhotel = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/Westin-hotel.jpg`;
const alofthotel  = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/aloft-hotel-logo.jpg`;
const marriottspartanburg  = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/marriott-spartanburg-logo.jpg`;
const rilogo = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/ri-logo.jpg`;

export const hotelsUltimateSouthCarolina = [
  {
    img: Greenville_Marriot,
    title: "Greenville Marriot",
    link: "https://www.marriott.com/hotels/travel/gspap-greenville-marriott/"
   },
   {
    img: Westin_Poinsett,
    title: "The Westin Poinsett",
    link: "https://www.marriott.com/hotels/travel/gspwi-the-westin-poinsett-greenville/"
   },
   {
    img: Aloft_Greenville_Downtown,
    title: "Aloft Greenville Downtown",
    link: "https://www.marriott.com/hotels/travel/gspal-aloft-greenville-downtown/?scid=bb1a189a-fec3-4d19-a255-54ba596febe2&y_source=1_MzA3MDMyNy03MTUtbG9jYXRpb24uZ29vZ2xlX3dlYnNpdGVfb3ZlcnJpZGU="
   },
   {
    img: Crowne_Plaza_Greenville,
    title: "Crown Plaza Greenville",
    link: "https://www.ihg.com/crowneplaza/hotels/us/en/greenville/gsprm/hoteldetail"
   },
   {
    img: Embassy_Suites_Greenville,
    title: "Embassy Suites Greenville RiverPlace",
    link: "https://www.hilton.com/en/hotels/gspgdes-embassy-suites-greenville-downtown-riverplace/"
   },
   {
    img: ACHotelGreenville,
    title: "AC Hotel Greenville",
    link: "https://www.marriott.com/hotels/travel/gspac-ac-hotel-greenville/"
   },
   {
    img: Hampton_Inn_Greenville_Riverplace,
    title: "Hampton Inn Greenville RiverPlace",
    link: "https://www.hilton.com/en/hotels/gspdthx-hampton-suites-greenville-downtown-riverplace/?SEO_id=GMB-HP-GSPDTHX"
   },
   {
    img: Spartanburg_Marriot,
    title: "Spartanburg Marriot",
    link: "https://www.marriott.com/hotels/travel/spamc-spartanburg-marriott/?scid=bb1a189a-fec3-4d19-a255-54ba596febe2&y_source=1_MjgzOTYyMy03MTUtbG9jYXRpb24uZ29vZ2xlX3dlYnNpdGVfb3ZlcnJpZGU="
   }
]
export const hotelsUltimateCarifornia = [
  {
    img: Hyatt_Palm_Springs,
    title: "Hyatt Palm Springs",
    link: "https://www.hyatt.com/en-US/hotel/california/hyatt-palm-springs/palms/"
   },
   {
    img: Hotel_Paseo,
    title: "Hotel Paseo, Autograph Collection",
    link: "https://www.marriott.com/event-reservations/reservation-link.mi?id=1607635261228&key=CORP&app=resvlink"
   },
   {
    img: JW_Marriott,
    title: "JW Marriott Desert Springs ",
    link: "https://www.marriott.com/hotels/travel/ctdca-jw-marriott-desert-springs-resort-and-spa/"
   },
   {
    img: Renaissance_Palm_Springs,
    title: "Renaissance Palm Springs Hotel",
    link: "https://www.marriott.com/hotels/travel/pspbr-renaissance-palm-springs-hotel/",
    code: "BM1"
   },
   {
    img: Residence_Inn_La_Quinta,
    title: "Residence Inn La Quinta",
    link: "https://www.marriott.com/hotels/travel/psprq-residence-inn-la-quinta/",
    code: "BMW"
   },
   {
    img: La_Quinta_Resort_Club,
    title: "La Quinta Resort & Club",
    link: "https://www.laquintaresort.com/"
   },
   {
    img: Rancho_Mirage,
    title: "The Ritz-Carlton, Rancho Mirage",
    link: "https://www.ritzcarlton.com/en/hotels/california/rancho-mirage"
   },
   {
    img: miramonte_indian_wells,
    title: "Miramonte Indian Wells Resort",
    link: "https://be.synxis.com/?Hotel=12319&Chain=25042&config=Promotional&theme=initialTheme&promo=BMW"
   },
   {
    img: Renaissance_Esmeralda_Indian_Wells,
    title: "Renaissance Esmeralda Indian Wells Resort",
    link: "https://www.marriott.com/en-us/hotels/pspsr-renaissance-esmeralda-resort-and-spa-indian-wells/overview/?scid=bb1a189a-fec3-4d19-a255-54ba596febe2&y_source=1_MjkyMTM1NC03MTUtbG9jYXRpb24uZ29vZ2xlX3dlYnNpdGVfb3ZlcnJpZGU%3D"
   }
]
export const hotels = [
  {
    id: 1,
    image: `${Hyatt_Indian_Wells}`,
    logo: `${Hyatt_Indian_Wells}`,
    imageAlt: "Hyatt Regency Indian Wells Resort & Spa",
    location: "California",
    name: "Hyatt Regency Indian Wells Resort & Spa",
    URL:
      "https://www.hyattindianwells.com/",
  },
  {
    id: 2,
    image: `${Hotel_Paseo}`,
    logo: `${hotelpaseo}`,
    imageAlt: "Hotel Paseo Hotel",
    location: "California",
    name: "Hotel Paseo, Autograph Collection",
    URL:
      "https://www.marriott.com/event-reservations/reservation-link.mi?id=1607635261228&key=CORP&app=resvlink",
    bookingTxt: "Book your corporate rate now.",
  },
  {
    id: 3,
    image: `${JW_Marriott}`,
    logo: `${jwmarriotthotel}`,
    imageAlt: "JW Marriott Hotel",
    location: "California",
    name: "JW Marriott Desert Springs Resort & Spa",
    URL:
      "https://www.marriott.com/hotels/travel/ctdca-jw-marriott-desert-springs-resort-and-spa/",
  },
  {
    id: 4,
    image: `${Renaissance_Palm_Springs}`,
    logo: `${renaissancehotel}`,
    imageAlt: "Renaissance Hotel",
    location: "California",
    name: "Renaissance Palm Springs Hotel",
    URL:
      "https://www.marriott.com/hotels/travel/pspbr-renaissance-palm-springs-hotel/",
    bookingcode: "BM1",
  },
  {
    id: 5,
    image: `${Residence_Inn_La_Quinta}`,
    logo: `${rilogo}`,
    imageAlt: "Residence Inn La Quinta",
    location: "California",
    name: "Residence Inn La Quinta",
    URL:
      "https://www.marriott.com/hotels/travel/psprq-residence-inn-la-quinta/",
    bookingcode: "BMW",
  },
  {
    id: 6,
    image: `${La_Quinta_Resort_Club}`,
    logo: `${laQuintahotel}`,
    imageAlt: "La Quinta Hotel",
    location: "California",
    name: "La Quinta Resort & Club",
    URL: "https://www.laquintaresort.com/",
    bookingcode: "BMW",
  },
  {
    id: 7,
    image: `${Rancho_Mirage}`,
    logo: `${ritzhotel}`,
    imageAlt: "Ritz Hotel",
    location: "California",
    name: "The Ritz-Carlton, Rancho Mirage",
    URL: "https://www.ritzcarlton.com/en/hotels/california/rancho-mirage",
  },
  {
    id: 8,
    image: `${miramonte_indian_wells}`,
    logo: `${ritzhotel}`,
    imageAlt: "Tommy Bahama Miramonte Resort & Spa, Indian Wells",
    location: "California",
    name: "Tommy Bahama Miramonte Resort & Spa, Indian Wells",
    URL: "http://www.tommybahamamiramonte.com/",
    bookingcode: "BMW1",
  },
  {
    id: 9,
    image: `${Renaissance_Esmeralda_Indian_Wells}`,
    logo: `${ritzhotel}`,
    imageAlt: "Renaissance Esmeralda Indian Wells Resort",
    location: "California",
    name: "Renaissance Esmeralda Indian Wells Resort",
    URL: "https://www.marriott.com/hotels/travel/pspsr-renaissance-esmeralda-resort-and-spa-indian-wells/?scid=bb1a189a-fec3-4d19-a255-54ba596febe2&y_source=1_MjkyMTM1NC03MTUtbG9jYXRpb24uZ29vZ2xlX3dlYnNpdGVfb3ZlcnJpZGU%3D",
  },
  {
    id: 10,
    image: `${Westin_Poinsett}`,
    logo: `${Westinhotel}`,
    imageAlt: "Westin Hotel",
    location: "South Carolina",
    name: "The Westin Poinsett, Greenville",
    URL:
      "https://www.marriott.com/hotels/travel/gspwi-the-westin-poinsett-greenville/",
  },
  {
    id: 11,
    image: `${Aloft_Greenville_Downtown}`,
    logo: `${alofthotel}`,
    imageAlt: "Aloft Hotel",
    location: "South Carolina",
    name: "Aloft Greenville Downtown",
    URL: "https://www.marriott.com/hotels/travel/gspal-aloft-greenville-downtown/?scid=bb1a189a-fec3-4d19-a255-54ba596febe2&y_source=1_MzA3MDMyNy03MTUtbG9jYXRpb24uZ29vZ2xlX3dlYnNpdGVfb3ZlcnJpZGU="
  },
  {
    id: 12,
    image: `${Embassy_Suites_Greenville}`,
    logo: `${embassyhotel}`,
    imageAlt: "Embassy Hotel",
    location: "South Carolina",
    name: "Embassy Suites Greenville Riverplace",
    URL:
      "https://www.hilton.com/en/hotels/gspgdes-embassy-suites-greenville-downtown-riverplace/",
  },
  {
    id: 13,
    image: `${ACHotelGreenville}`,
    logo: `${achotel}`,
    imageAlt: "AC Hotel",
    location: "South Carolina",
    name: "AC Hotel Greenville",
    URL: "https://www.marriott.com/hotels/travel/gspac-ac-hotel-greenville/",
  },
  {
    id: 14,
    image: `${Hampton_Inn_Greenville_Riverplace}`,
    logo: `${hamptonhotel}`,
    imageAlt: "Hampton Hotel",
    location: "South Carolina",
    name: "Hampton Inn Greenville Riverplace",
    URL:
      "https://www.hilton.com/en/hotels/gspdthx-hampton-suites-greenville-downtown-riverplace/?SEO_id=GMB-HP-GSPDTHX",
  },
  {
    id: 15,
    image: `${Spartanburg_Marriot}`,
    logo: `${marriottspartanburg}`,
    imageAlt: "Spartanburg Marriott Hotel",
    location: "South Carolina",
    name: "Spartanburg Marriot",
    URL:
      "https://www.marriott.com/hotels/travel/spamc-spartanburg-marriott/?scid=bb1a189a-fec3-4d19-a255-54ba596febe2&y_source=1_MjgzOTYyMy03MTUtbG9jYXRpb24uZ29vZ2xlX3dlYnNpdGVfb3ZlcnJpZGU=",
  },
  {
    id: 16,
    image: `${ritzRancho}`,
    logo: `${ritzRancho}`,
    imageAlt: "Ace Hotel Palm Springs",
    location: "California",
    name: "Ace Hotel Palm Springs",
    URL:
      "https://reservations.acehotel.com/?adult=1&arrive=2023-06-13&chain=7231&child=0¤cy=USD&depart=2023-06-14&hotel=23354&level=hotel&locale=en-US&promo=BMWTHERMAL&rooms=1",
    bookingcode: "BMWTHERMAL"
  },
  {
    id: 17,
    image: `${driftHotel}`,
    logo: `${driftHotel}`,
    imageAlt: "Drift Hotel Palm Springs",
    location: "California",
    name: "Drift Hotel Palm Springs",
    URL:
      "https://www.drifthotels.co/palmsprings?selfbook=true&hotel=45752",
    midweekcode: "BMW",
    weekendcode: "WEBMW"
  },
  {
    id: 18,
    image: `${Hyatt_Palm_Springs}`,
    logo: `${hyattpalmspringshotel}`,
    imageAlt: "Hyatt palm springs Hotel",
    location: "California",
    name: "Hyatt Palm Springs",
    URL:
      "https://www.hyatt.com/en-US/hotel/california/hyatt-palm-springs/palms",
  },
  {
    id: 19,
    image: `${AcmeHouse}`,
    logo: `${AcmeHouse}`,
    imageAlt: "Acme House Company",
    location: "California",
    name: "ACME House Company - A Curated Portfolio of Distinctive Homes",
    URL:
      "https://www.acmehouseco.com/",
  },
  {
    id: 20,
    image: `${conrad}`,
    logo: `${conrad}`,
    imageAlt: "Conrad, Indianapolis",
    location: "Indianapolis",
    name: "Conrad, Indianapolis",
    URL:
      "https://www.hilton.com/en/hotels/indcici-conrad-indianapolis/",
  },
  {
    id: 21,
    image: `${bohemaian}`,
    imageAlt: "The Grand Bohemian",
    location: "South Carolina",
    name: "The Grand Bohemian",
    URL: "https://www.marriott.com/en-us/hotels/gspak-grand-bohemian-lodge-greenville-autograph-collection/overview/",
  },
  {
    id: 22,
    image: `${hartness}`,
    imageAlt: "Hotel Hartness",
    location: "South Carolina",
    name: "Hotel Hartness",
    URL: "https://www.hotelhartness.com/",
  },
];